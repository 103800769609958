import PropTypes from "prop-types"
import React from "react"
import { container, overlay, content } from "./banner.module.css"

const Banner = props => {
  const { children, img, style } = props

  return (
    <div className={container} style={style}>
      <img src={img} alt="blog_banner" />
      <div className={overlay} />
      <div className={content}>{children}</div>
    </div>
  )
}

Banner.propTypes = {
  imgSrc: PropTypes.string,
  height: PropTypes.any,
  img: PropTypes.any,
}

Banner.defaultProps = {
  imgSrc: ``,
  height: "100%",
  img: null,
}

export default Banner
