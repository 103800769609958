/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import EmailDiv from "./emailDiv/emailDiv"
import Footer from "./footer/footer"
import { graphql } from "gatsby"
import Header from "./header/header"
import { useStaticQuery } from "gatsby"
import ContactBtn from "./ContactBtn/contactbtn"

const Layout = ({ children, isEmail }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      logo: file(relativePath: { eq: "yrt.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      loc: file(relativePath: { eq: "placeholder.png" }) {
        childImageSharp {
          fluid(maxWidth: 30) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      linkedIn: file(relativePath: { eq: "linkedin.png" }) {
        childImageSharp {
          fluid(maxWidth: 30) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      message: file(relativePath: { eq: "message.png" }) {
        childImageSharp {
          fluid(maxWidth: 30) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      phone: file(relativePath: { eq: "phone.png" }) {
        childImageSharp {
          fluid(maxWidth: 30) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Header />
      {children}
      <ContactBtn />
      {isEmail ? <EmailDiv /> : null}
      <Footer />
    </>
  )
}

Layout.defaultProps = {
  children: null,
  banner: () => void 0,
  isEmail: true,
}

export default Layout
