import React, { Fragment } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner/banner"
import "../styles/main.css"

class BlogPostTemplate extends React.Component {
  render() {
    const { data } = this.props
    const { post } = data
    let BlogBanner = (
      <Banner img={post.data.banner_image.fluid.src}>
        <h1>{post.data.blog_title.text}</h1>
      </Banner>
    )
    return (
      <Fragment>
        <Layout isEmail={false}>
          <SEO title={post.data.blog_title.text} />
          {BlogBanner}
          <div style={{ paddingTop: 30, paddingBottom: 30 }}>
            <div
              style={{
                maxWidth: "1080px",
                margin: "0 auto",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: post.data.blog_content.html,
                }}
              />
            </div>
          </div>
        </Layout>
      </Fragment>
    )
  }
}

export default BlogPostTemplate

export const query = graphql`
  query Post($slug: String) {
    post: prismicPost(id: { eq: $slug }) {
      data {
        blog_content {
          html
        }
        blog_title {
          text
        }
        meta_description {
          text
        }
        banner_image {
          fluid(maxWidth: 2000) {
            src
          }
        }
      }
    }
  }
`
