import React, { useState } from "react"
import axios from "axios"
import { Button } from "react-bootstrap"
import firebase from "firebase/app"
import * as styles from "../../styles/components/emailDiv.module.css"
import { Input } from "../Input/Input"

const EmailDiv = () => {
  const config = {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_AUTH_DOMAIN,
    databaseURL: process.env.GATSBY_DB_URL,
    projectId: process.env.GATSBY_PROJECT_ID,
    storageBucket: process.env.GATSBY_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_MESSAGE_SENDER_ID,
    appId: process.env.GATSBY_APP_ID,
  }

  const [email, setEmail] = useState("")

  const validateEmail = () => {
    const emailRegex = /^[a-z][a-z0-9+-_.]{1,}@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])$/

    let valid = true

    if (email === "") {
      valid = false
    } else if (!emailRegex.test(email)) {
      valid = false
    }

    return valid
  }

  const HandleSubmit = () => {
    if (!firebase) {
      firebase.initializeApp(config)
    }

    let emailData = {
      email: email,
    }

    if (validateEmail()) {
      axios
        .post(
          "https://us-central1-yogi-r-website-forms.cloudfunctions.net/newsletter",
          emailData
        )
        .then(res => {
          alert("Thanks for subscribing")
        })
        .catch(err => {
          alert("Unkown error")
        })
    } else {
      alert("please enter a valid email")
    }
  }

  return (
    <div className={styles.contactUsDiv}>
      <div>
        <h3>Get news and updates</h3>
      </div>
      <div className={styles.inpContainer}>
        <Input
          placeholder="E-mail"
          name="email"
          inpClass={styles.emailInp}
          onChangeCallBack={e => {
            setEmail(e.target.value)
          }}
          value={email}
        />
        <Button onClick={HandleSubmit}>SIGN UP</Button>
      </div>
    </div>
  )
}

export default EmailDiv
