import React from "react"
import { Button } from "react-bootstrap"
import { FilterRight } from "react-bootstrap-icons"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import { Navbar } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import * as styles from "../../styles/components/header.module.css"
import { useState } from "react"
import { X } from "react-bootstrap-icons"
import YrtLogo from "../../images/logo.png"

export default function Header() {
  const [headerIcon, setHeaderIcon] = useState(false)
  return (
    <>
      <Navbar
        fixed="top"
        className={styles.navContainer}
        bg="transparent"
        expand="lg"
      >
        <Link to="/">
          <Navbar.Brand>
            <img width="145" height="80" alt="logo" src={YrtLogo} />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle
          className={styles.mobileToggle}
          onClick={() => {
            let temp = headerIcon
            setHeaderIcon(!temp)
          }}
          aria-controls="mobile-nav"
        >
          {headerIcon ? <X /> : <FilterRight />}
        </Navbar.Toggle>
        <Navbar.Collapse>
          <div className={styles.mobileNav} id="mobile-nav">
            <p>
              <Link to="/">Home</Link>
            </p>
            <p>
              <Link to="/about">About</Link>
            </p>
            <p>
              <Link to="/work-with-us">Work with us</Link>
            </p>
          </div>
        </Navbar.Collapse>
        <Nav className={styles.headerMenu + " ml-auto"}>
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/work-with-us">
            <Button className={styles.navBtn}> Work with us</Button>
          </Link>
        </Nav>
      </Navbar>
    </>
  )
}
